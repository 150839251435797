<template>
  <div>
    <section>
      <div class="box p-2 mt-2">
        <div
          class="boxed-tabs nav nav-tabs text-xs justify-center w-full bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md mx-auto flex-col sm:flex-row"
          role="tablist"
        >
          <router-link
            id="tutela-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver.proceso' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.procesos-administrativos.ver.proceso'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <TabletIcon class="w-4 h-4 mr-2"/>
            {{ derecho.tipo === 1 ? 'Derecho' : derecho.tipo === 2 ? 'Requerimiento' : 'PQR'}}
          </router-link>
          <router-link
            id="solicitudes-tab"
            :to="{ name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver.solicitudes' }"
            :class="getActive([
              'pharmasan.administrativa.juridica.procesos-administrativos.ver.solicitudes'
            ])"
            class="btn flex-1 border-0 shadow-none py-2 px-2"
          >
            <BarChartIcon class="w-4 h-4 mr-2"/>
            Solicitudes
          </router-link>
        </div>
      </div>
    </section>
    <router-view/>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import derechosPeticionStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/derechosPeticion.store'

export default {
  name: 'VerDerechoPeticion',
  setup () {
    const route = useRoute()
    const derecho = computed(() => {
      return derechosPeticionStore.getters._derecho
    })

    const getActive = (routeNames = []) => {
      if (routeNames.includes(route.name)) {
        return 'active'
      }
      return ''
    }

    onMounted(() => {
      derechosPeticionStore.dispatch('find', route.params.id)
    })

    return {
      getActive,
      derecho
    }
  }
}
</script>

<style scoped>

</style>
